import React from "react"
import Img from "gatsby-image"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItemBlock from "../components/shared/ItemBlock"
import style from "./store.module.scss"

const music = [
  {
    id: 1,
    imageURL: "http://www.edgetonerecords.com/catalog/images/4181.jpg",
    image: "../images/store/maul-of-america.jpg",
    artist: `Running From Bears`,
    title: `Maul of America`,
    url: "http://www.edgetonerecords.com/catalog/4181.html",
    description: `Running From Bears is an adventurous Phoenix-based jazz sextet. Formed in 2007, the group is committed to the composition and performance of original works, along with the occasional reworking of popular songs. Each player/composer has a different compositional and improvisatory voice, yielding a forward-thinking and diverse group sound.
            Running From Bears is in its 10th year of a residency at The Lost Leaf, a Phoenix venue dedicated to the presentation of musical ensembles that compose their own works. The group can also be seen at other Phoenix area venues and festivals, such as The Nash, The Rhythm Room, the Tempe Festival of the Arts, and the Highland/ASU Jazz Festival.
            `,
  },
  {
    id: 2,
    imageURL: "http://www.edgetonerecords.com/catalog/images/4177.jpg",
    image: "../images/store/maul-of-america.jpg",
    artist: `Slender Loris`,
    title: `Two Parts Helium`,
    url: "http://www.edgetonerecords.com/catalog/4177.html",
    description: `Slender Loris: A Phoenix-based improvising duo blending woodwinds and modular synth/electronics

    <p>Keith Kelly - soprano, tenor saxophones, bass clarinet, flute</p>
    Tony Orb - modular synthesizer, electronics
    `,
  },
]

const StoreItem = ({ item }) => (
  <li key={item.id}>
    <h3>
      {item.artist} <span>{item.title}</span>
    </h3>
    <img src={item.imageURL} alt="Album cover" />
    <a href={item.url}>Purchase</a>
    <p>{item.description}</p>
  </li>
)
const StorePage = () => (
  <Layout>
    <SEO title="Store" />
    <ItemBlock>
      <ul>
        {music.map(item => (
          <StoreItem key={item.id} item={item} />
        ))}
      </ul>
    </ItemBlock>
    <ItemBlock>
      <div className={style.home}>
        <h1>Store Page</h1>
        <p>This page is under construction, check back soon...</p>
      </div>
    </ItemBlock>
  </Layout>
)

export default StorePage
